<template>
<el-container id="page" class="subject-ma-in-pro">
  <el-aside width="30%">
    <el-input class="smip-aside-input" @input="handleInput" v-model="proCode" placeholder="项目编码/项目名称">
      <template #prepend>
        <i class="iconfont icon-sousuo" />
      </template>
    </el-input>
    <el-table @row-click="handleTableClick" :row-class-name="getRowClass" :data="table.proData" v-loading="table.proLoading" header-cell-class-name="table-cell">
      <el-table-column prop="txt_project_code" label="项目编码" align="center" />
      <el-table-column prop="txt_project_name" label="项目名称" align="center" />
      <el-table-column prop="txt_sponsor_name" label="所属申办方" align="center" />
    </el-table>
  </el-aside>
  <el-main v-loading="mainLoading">
    <statistics :data="statData" />
    <sub-table v-if="subTableShow" :t_project_id="current.t_project_id" />
  </el-main>
</el-container>
</template>

<script>
// 项目运营管理 - 受试者管理
import { ElMessage } from "element-plus";
import { Statistics } from "components";
import SubTable from "./comp/subTable.vue";
import { reactive, toRefs, onMounted } from 'vue';
import { getProjectListInSub, getStatExaminee } from "api/apis.js";
export default {
  components: {
    Statistics,
    SubTable
  },
  setup() {
    const state = reactive({
      proCode: "",
      table: {
        proData: [],
        proLoading: false
      },
      mainLoading: false,
      statData: [
        { title: "受试者总数", eng: "Number of subjects", value: 66, color: "#8677b6" },
        { title: "已入组总数", eng: "Number of groups", value: 66, color: "#e66d92" }
      ],
      current: {
        t_project_id: 0,
        index: 0
      },
      timer: null,
      subTableShow: false
    });

    onMounted(() => {
      state.table.proData = [];
      getProjects();
    })

    // 项目表格点击事件
    const handleTableClick = row => {
      const index = state.table.proData.findIndex(t => t.t_project_id == row.t_project_id);
      // 切换选中行
      state.table.proData[state.current.index].focus = false;
      state.table.proData[index].focus = true;
      // 被选中的项目，提取项目ID
      state.current.t_project_id = row.t_project_id;
      // 修改当前选中行索引 的值
      state.current.index = index;
      // 获取右侧统计
      getStatData();
    };

    // 设置 项目 表格 行样式
    const getRowClass = ({ row }) => {
      if (row.focus) {
        return "subma-table-row-class";
      } else {
        return "";
      }
    };

    // 搜索框输入事件
    const handleInput = () => {
      if (state.timer) {
        state.timer = clearTimeout(state.timer);
      } else {
        state.timer = setTimeout(() => {
          getProjects();
        }, 800);
      }
    };

    // 获取项目列表
    const getProjects = () => {
      state.table.proLoading = true;
      getProjectListInSub({ txt_keywords: state.proCode }).then(response => {
        if (response.code == 200) {
          state.table.proData = response.data || [];
          if (state.table.proData.length > 0) {
            // 添加选中标识字段
            state.table.proData.forEach(item => item.focus = false);
            // 默认第一个选中
            state.table.proData[0].focus = true;
            // 被选中的项目，提取项目ID
            state.current.t_project_id = state.table.proData[0].t_project_id;
            // 获取右侧统计
            getStatData();
          }
        } else {
          state.table.proData = [];
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.proLoading = false;
      })
    };

    // 获取统计数据
    const getStatData = () => {
      state.mainLoading = true;
      state.subTableShow = false;
      getStatExaminee({ t_project_id: state.current.t_project_id }).then(response => {
        if (response.code == 200) {
          state.statData[0].value = response.data.cnt_all;
          state.statData[1].value = response.data.cnt_enrollment;
        } else {
          state.statData[0].value = 0;
          state.statData[1].value = 0;
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.mainLoading = false;
        state.subTableShow = true;
      })
    };

    return {
      ...toRefs(state),
      getProjects,
      getRowClass,
      handleInput,
      handleTableClick
    }
  }
}
</script>

<style lang="scss">
.subject-ma-in-pro {
  .el-aside {
    .smip-aside-input {
      width: 80%;
    }

    .el-table {
      margin-top: 14px;
      height: calc(100% - 50px);

      tbody {
        .subma-table-row-class:hover>td {
          background-color: #efecff;
        }
      }

      .el-table__row {
        cursor: pointer;

        &:hover>td {
          background-color: #efecff;
        }

        &.subma-table-row-class {
          background-image: linear-gradient(to bottom, #ffffff 0%, #ffffff 40%, #efecff 40.01%, #ffffff 100%);
        }
      }
    }
  }

  .el-main {
    padding: 0 10px;
    border-left: 1px solid #e3e3e3;

    .sub-table-comp-for-subject-ma {
      height: calc(100% - 130px);
    }
  }
}
</style>
