<template>
<div class="sub-table-comp-for-subject-ma">
  <el-row>
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-form-item prop="txt_keywords">
        <el-input v-model="form.txt_keywords" placeholder="受试者筛选号">
          <template #prepend>
            <i class="iconfont icon-sousuo" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="dateRange">
        <el-date-picker value-format="YYYY-MM-DD" v-model="form.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getTableList()">搜索</el-button>
        <el-button type="info" @click="reset()">重置</el-button>
      </el-form-item>
    </el-form>
  </el-row>
  <el-table :data="table.data" v-loading="table.loading" header-cell-class-name="table-cell">
    <el-table-column prop="txt_filter_number" label="筛选号" align="center" />
    <el-table-column prop="txt_examinee_spell" label="姓名缩写" align="center" />
    <el-table-column prop="txt_hospital_name" label="所属中心" align="center" />
    <el-table-column prop="txt_hospital_name" label="所属CRC" align="center" />
    <el-table-column prop="txt_researcher_name" label="所属研究者" align="center" />
    <el-table-column prop="status" label="是否入组" align="center">
      <template #default="scope">
        <template v-if="scope.row.status==0">未入组</template>
        <template v-else-if="scope.row.status==1">已入组</template>
        <template v-else>- -</template>
      </template>
    </el-table-column>
    <el-table-column label="操作" align="center" class-name="table-option">
      <template #default="scope">
        <div class="table-icon has-margin-right" @click="handleDetail(scope.row)">详情</div>
      </template>
    </el-table-column>
  </el-table>
  <pagination ref="pageRef" :total="table.total" @sizeChange="(e) => {form.pageSize=e;getTableList()}" @pageChange="(e) => {form.pages=e;getTableList()}" />
  <detail v-if="detail.show" :dialogData="detail.data" @close="detail.show=false; detail.data = {};" />
</div>
</template>

<script>
// 受试者管理 - 受试者列表
import { ElMessage } from "element-plus";
import { Pagination, Detail } from "components";
import { getSubjectsByProID, getSubjectDetailById } from "api/apis.js";
import { onMounted, reactive, toRefs, ref } from 'vue';
export default {
  components: {
    Pagination,
    Detail
  },
  props: {
    t_project_id: { type: Number },
  },
  setup(prop) {
    const pageRef = ref(null);
    const state = reactive({
      form: {
        txt_keywords: null,
        dateRange: [],
        pages: 1,
        pageSize: 10
      },
      table: {
        data: [],
        loading: false,
        total: 0
      },
      detail: {
        data: {},
        show: false
      }
    })

    onMounted(() => {
      getTableList();
    })

    // 查看详情
    const handleDetail = item => {
      state.detail.data = {
        fn: getSubjectDetailById,
        param: { t_crc_examinee_id: item.t_crc_examinee_id },
        type: "detail"
      };
      state.detail.show = true;
    };
    // 获取列表数据
    const getTableList = () => {
      state.table.loading = true;
      state.form.dt_from = (state.form.dateRange || []).length > 0 ? state.form.dateRange[0] : "";
      state.form.dt_end = (state.form.dateRange || []).length > 0 ? state.form.dateRange[1] : "";
      state.form.t_project_id = prop.t_project_id;
      getSubjectsByProID(state.form).then(response => {
        if (response.code == 200) {
          state.table.data = response.data;
          state.table.total = response.total;
        } else {
          state.table.data = [];
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.loading = false;
      })
    }

    // 重置表单，重新搜索
    const reset = () => {
      state.form = {
        txt_keywords: null,
        dateRange: [],
        pages: 1,
        pageSize: 10
      };
      pageRef.value.pagination.pageSize = 10;
      pageRef.value.pagination.currentPage = 1;
      getTableList();
    }

    return {
      ...toRefs(state),
      getTableList,
      reset,
      pageRef,
      handleDetail
    }
  }
}
</script>

<style lang="scss" scoped>
.sub-table-comp-for-subject-ma {
  .el-row {
    margin-top: 12px;
  }

  .el-table {
    height: calc(100% - 105px);
  }
}
</style>
